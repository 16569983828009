import * as React from "react";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
import * as _ from "lodash";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  h1 {
    flex: 1;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: stretch;

  width: 100%;

  table {
    flex: 1;
    width: auto;
  }
`;

const Sorted = (props: any) => {
  const sorted: any[] = props.sorted || [];
  const uniqueKeys = sorted.reduce<string[]>((pv, cv) => {
    return _.uniq([...pv, ...Object.keys(cv)]);
  }, []);
  const ignoredKeys = ["highlight"];
  const columns = uniqueKeys.filter((key) => ignoredKeys.indexOf(key) === -1);
  return (
    <div>
      <Header>
        <h1>{props.title}</h1>
      </Header>
      <TableContainer>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              {columns.map((column) => {
                console.log(column);
                return <td key={column}>{column}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {sorted.map((data: any, idx: number) => {
              const className = data.highlight ? `table-${data.highlight}` : "";
              return (
                <tr key={`result-${idx}`} className={className}>
                  {columns.map((column) => (
                    <td key={`${idx}-${column}`}>{data[column]}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Sorted;
