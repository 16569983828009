import * as React from "react";
import Sorted from "./components/Sorted";
import Text from "./components/Text";
import { useFirebaseState } from "./firebase/hooks";

const Application = () => {
  const [state] = useFirebaseState<any>("dashboard");
  if (state === null) {
    return null;
  }

  if (state.interface === "sorted") {
    return <Sorted {...state} />;
  }

  if (state.interface === "text") {
    return <Text {...state} />;
  }

  return <h1>{state.interface}</h1>;
};

export default Application;
