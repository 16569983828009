import * as React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";

import Application from "./Application";

import "bootstrap/dist/css/bootstrap.min.css";

import firebase from "firebase/app";
import "firebase/database";

import i18next from "i18next";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

firebase.initializeApp({
  apiKey: "AIzaSyBbGX9daWf1FRKx8JLPrd8QyhpS0yxeGZE",
  databaseURL: "https://emojiii-4acc2-default-rtdb.firebaseio.com/",
});

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    ns: ["toucan", "emojiii"],
    backend: {
      projectId: "1882e170-35f1-447f-9b1e-ebe5c9474b0b",
      referenceLng: "en",
    },
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "thecircleoftruthLng",
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"],
    },
  });

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
`;

ReactDOM.render(
  <React.Fragment>
    <GlobalStyle />
    <Application />
  </React.Fragment>,
  document.getElementById("root")
);
