import * as React from "react";
import firebase from "firebase/app";

export function useDataSnapshotState<T>() {
  const [state, setState] = React.useState<T | null>(null);
  const setFromDataSnapshot = React.useCallback(
    (snapshot: firebase.database.DataSnapshot) => {
      setState(snapshot.val());
    },
    [setState]
  );
  return [state, setFromDataSnapshot] as [
    typeof state,
    typeof setFromDataSnapshot
  ];
}

// Hook to get and set state from any Firebase key. Using this hook will listen to
// changes in the realtime database and update its state when it changes remotely.
// Equivalent to React.useState, but now synchronised to Firebase.
export function useFirebaseState<T>(key: string) {
  const [state, setState] = useDataSnapshotState<T>();
  const setFirebaseState = useSetFirebaseState<T>(key);

  React.useEffect(() => {
    const ref = firebase.database().ref().child(key);
    ref.on("value", setState);
    return () => ref.off("value", setState);
  }, [key, setState]);

  return [state, setFirebaseState] as [typeof state, typeof setFirebaseState];
}

function useSetFirebaseState<T>(key: string) {
  return React.useCallback(
    (s: T) => {
      firebase.database().ref().child(key).set(s);
    },
    [key]
  );
}
