import * as React from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

const Title = styled.h1`
  font-size: 5rem;
  text-align: center;
  font-weight: bold;
`;

const Header = styled.h2`
  font-size: 2rem;
  color: black;
  font-weight: bold;
`;

const Border = styled.div`
  padding: 10px;
  border: 5px solid ${(props) => props.color}};
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SimpleText = styled.div`
  font-size: 2rem;
  color: grey;
  animation: 0.5s ${fadeIn} ease-out;
`;

const Container = styled.div`
  padding: 3rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-content: scenter;
`;

const Text = (props: any) => {
  const { t } = useTranslation("emojiii");
  const text = t(props.text);
  const nextCue = t(props.nextcue);

  return (
    <div>
      <Title>{props.title}</Title>
      <Container>
        <Border color="black">
          <Header>{"Phone text:"}</Header>
          <SimpleText key={text}>{text}</SimpleText>
        </Border>
        <Border color="red">
          <Header>{"Next cue:"}</Header>
          <SimpleText key={nextCue}>{nextCue}</SimpleText>
        </Border>
      </Container>
    </div>
  );
};

export default Text;
